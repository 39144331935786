import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { AdminAccount } from '../model/adm/AdminAccount';
import type { RequestDataBase } from '../model/common';

/**
 * 어드민 프로필 Api
 */
export class AdminProfileApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 어드민 프로필 조회
   */
  profileMe = (params: RequestDataBase): Promise<{ profile: AdminAccount }> => {
    const url = '/admin/profile-me';
    return this.withData.post(url, ...splitParams(params));
  };
}
