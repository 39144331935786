import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { UserAccount } from '../model/index';
import type { RequestDataBase } from '../model/common';

/**
 * 사용자 인증 API
 */
export class AuthApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 로그인
   */
  signIn = async (
    params: {
      loginId: string;
      pw: string;
      cid: number;
    } & RequestDataBase,
  ): Promise<{ profile: UserAccount }> => {
    const url = '/p/api/auth/login';
    const header = { Authorization: '' };
    const { loginId, pw, ctx } = params;

    return this.withData.postJson(
      url,
      { loginId, pw },
      { ctx, header: Object.assign({}, header, params.header) },
    );
  };

  /**
   * 로그아웃
   */
  signOut = async (params: RequestDataBase): Promise<void> => {
    const url = '/api/auth/logout';
    await this.helper.post(url, ...splitParams(params));
  };
}
