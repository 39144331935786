import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { CodingSoundCateForAdm, RequestDataBase } from '../model/index';

export class CodingSoundCateApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 카테고리 목록 조회
   * TODO withDisabled
   */
  list = (params: {} & RequestDataBase): Promise<{ cateList: CodingSoundCateForAdm[] }> => {
    const url = '/admin/coding-sound-cate/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = (
    params: {
      cateId: string;
    } & RequestDataBase,
  ): Promise<{ cate: CodingSoundCateForAdm }> => {
    const url = '/admin/coding-sound-cate/info';
    return this.withData.post(url, ...splitParams(params));
  };

  create = (
    params: {
      cateId: string;
      cateName: string;
      isBgm: boolean;
    } & RequestDataBase,
  ): Promise<{ cate: CodingSoundCateForAdm }> => {
    const url = '/admin/coding-sound-cate/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      cateId: string;
      cateName: string;
      disabled: boolean;
    } & RequestDataBase,
  ): Promise<{ cate: CodingSoundCateForAdm }> => {
    const url = '/admin/coding-sound-cate/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  delete = async (
    params: {
      cateId: string;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/coding-sound-cate/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  updateDisabled = async (
    params: {
      cateId: string;
      disabled: boolean;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/coding-sound-cate/update-disabled';
    await this.helper.post(url, ...splitParams(params));
  };
}
