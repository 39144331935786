import { ApiHelperWithData, splitParams, type ApiHelper } from '@ocodelib/api-common';
import type { ProjectBreedKey } from '../enum-types';
import type { RequestDataBase } from '../model/common';
import type { CodeProjectOrigin, PagerData, Project, UploadedFile } from '../model/index';

export class ProjectApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 프로젝트 등록
   * 같은 이름의 프로젝트가 존재해도, 다른 프로젝트로 생성됩니다.
   * 중복 오류 없이 항상 생성됩니다.
   */
  create = (
    params: {
      breed: ProjectBreedKey;
      projectName: string;
      projectFileId: string;
      projectThumbFileId?: string;
      memo?: string;
      parentProjectId?: string;
    } & RequestDataBase,
  ): Promise<{ project: Project }> => {
    const url = '/api/project/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 업데이트
   */
  update = (
    params: {
      projectId: string;
      projectName: string;
      projectFileId: string;
      projectThumbFileId?: string;
      memo?: string;
    } & RequestDataBase,
  ): Promise<{ project: Project }> => {
    const url = '/api/project/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 목록 조회
   * 로그인한 사용자의 프로젝트 목록 조회
   */
  list = (
    params: {
      breed?: ProjectBreedKey[];
      title?: string;
      pageNumber: number;
      rowsPerPage: number;
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<Project> }> => {
    const url = '/api/project/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 정보 단건 조회
   */
  info = (
    params: {
      projectId: string;
    } & RequestDataBase,
  ): Promise<{ project: Project }> => {
    const url = '/p/api/project/info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 프로젝트 Origin 다건 조회
   */
  origins = (
    params: {
      projectId: string;
    } & RequestDataBase,
  ): Promise<{ projectOriginList: CodeProjectOrigin[]; forkCount: number }> => {
    const url = '/p/api/project/origins';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 프로젝트 삭제
   */
  delete = async (
    params: {
      projectId: string;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/api/project/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 소스코드 파일 업로드
   * zip 파일로 업로드
   */
  uploadProjectCodeFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/project/upload-code-file';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };

  /**
   * 프로젝트 썸네일 업로드
   */
  uploadProjectThumb = async (
    params: {
      file: Blob;
      fileName: string;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/project/upload-thumb';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };
}
