import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { DatiZipRequestStateKey } from '../enum-types/index';
import type { RequestDataBase } from '../model/index';

/**
 * 다티 ZIP Api
 */
export class DatiZipApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  create = (
    params: {
      datiId: string;
      srcFolder: string;
      itemNames: string[];
    } & RequestDataBase,
  ): Promise<{ reqId: string; state: DatiZipRequestStateKey; downloadUrl?: string }> => {
    const url = '/api/dati-zip/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  state = async (
    params: {
      reqId: string;
    } & RequestDataBase,
  ): Promise<{
    reqId: string;
    state: DatiZipRequestStateKey;
    downloadUrl?: string;
  }> => {
    const url = '/api/dati-zip/state';
    return this.withData.post(url, ...splitParams(params));
  };

  cancel = async (
    params: {
      reqId: string;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/api/dati-zip/cancel';
    await this.helper.post(url, ...splitParams(params));
  };
}
