import {
  ApiHelper,
  ApiHelperWithData,
  splitParams,
  type PagingRequest,
} from '@ocodelib/api-common';
import type { PagerData, Pds, PdsSimple, RequestDataBase, UploadedFile } from '../model/index';

/**
 * pds  api
 */
export class PdsApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  create = async (
    params: {
      title: string;
      substance?: string;
      fileIds: string[];
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const url = '/admin/api/pds/create';
    const response: { pds: Pds } = await this.withData.postJson(url, ...splitParams(params));
    return response;
  };

  update = async (
    params: {
      pdsId: number;
      title: string;
      substance?: string;
      fileIds: string[];
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/pds/update/${pdsId}`;
    const response: { pds: Pds } = await this.withData.postJson(url, ...splitParams(rest));
    return response;
  };

  list = async (
    params: {
      title?: string | null;
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<PdsSimple> }> => {
    const url = '/admin/api/pds/list';
    const response: { pagerData: PagerData<PdsSimple> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    );
    return response;
  };

  info = async (
    params: {
      pdsId: number;
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/pds/info/${pdsId}`;
    const response: { pds: Pds } = await this.withData.post(url, ...splitParams(rest));
    return response;
  };

  uploadTempFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/pds/upload';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };

  renameFile = async (
    params: {
      fileId: string;
      fileName: string;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { fileId, ...rest } = params;
    const url = `/admin/api/pds/update-file-name/${fileId}`;
    const response: { file: UploadedFile } = await this.withData.post(url, ...splitParams(rest));
    return response;
  };

  delete = async (
    params: {
      pdsId: number;
    } & RequestDataBase,
  ): Promise<void> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/pds/delete/${pdsId}`;
    await this.helper.postJson(url, ...splitParams(rest));
  };
}
