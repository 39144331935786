import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { Doc, DocSimple, RequestDataBase, UploadedFile } from '../model/index';

/**
 * doc  api
 */
export class PublicationApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  create = async (
    params: {
      title: string;
      content: string;
    } & RequestDataBase,
  ): Promise<{ doc: Doc }> => {
    const url = '/admin/api/doc/create';
    return await this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      pdsId: number;
      title: string;
      substance?: string;
      fileIds: string[];
    } & RequestDataBase,
  ): Promise<{ doc: Doc }> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/doc/update/${pdsId}`;
    return this.withData.postJson(url, ...splitParams(rest));
  };

  list = (
    params: {
      title?: string | null;
    } & RequestDataBase,
  ): Promise<{ docList: DocSimple[] }> => {
    const url = '/admin/api/doc/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = async (
    params: {
      pdsId: number;
    } & RequestDataBase,
  ): Promise<{ doc: Doc }> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/doc/info/${pdsId}`;
    return await this.withData.post(url, ...splitParams(rest));
  };

  uploadTempFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/doc/upload';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };

  delete = async (
    params: {
      pdsId: number;
    } & RequestDataBase,
  ): Promise<void> => {
    const { pdsId, ...rest } = params;
    const url = `/admin/api/doc/delete/${pdsId}`;
    await this.helper.postJson(url, ...splitParams(rest));
  };
}
