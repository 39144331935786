import { ApiHelperWithData, splitParams, type ApiHelper } from '@ocodelib/api-common';
import type { RequestDataBase } from '../model/common';
import type { WebPropSimple } from '../model/index';

export class WebPropApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * WebProp 단건 조회 by propKey
   * TODO 사용자용 API를 관리자용으로 하나더 만들어야 하나?
   */
  findByPropKey = (
    params: {
      propKey: string;
    } & RequestDataBase,
  ): Promise<{ webProp: WebPropSimple }> => {
    const url = '/p/api/web-prop/find';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * WebProp 단건 조회 by propKey
   * TODO 사용자용 API를 관리자용으로 하나더 만들어야 하나?
   */
  findByPropKeys = (
    params: {
      propKeys: string[];
    } & RequestDataBase,
  ): Promise<{ webPropList: WebPropSimple[] }> => {
    const url = '/p/api/web-prop/find-by-prop-keys';
    const { propKeys, ...rest } = params;
    return this.withData.post(url, { propKeys: propKeys.join(',') }, rest);
  };
}
