import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type {
  RequestDataBase,
  UserCountDays,
  UserDailyCount,
  UserMonthlyCount,
} from '../model/index';

/**
 * UserStat Api
 */
export class UserStatApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 일별 사용자 접속 통계 조회
   */
  userDailyList = (
    params: {
      recentMonthCount: number;
    } & RequestDataBase,
  ): Promise<{ userDailyList: UserDailyCount[] }> => {
    const { recentMonthCount, ...rest } = params;
    const url = `/admin/user-stats/daily/${recentMonthCount}`;
    return this.withData.post(url, ...splitParams(rest));
  };

  /**
   * 월별 사용자 접속 통계 조회
   */
  userMonthlyList = (params: RequestDataBase): Promise<{ userMonthlyList: UserMonthlyCount[] }> => {
    const url = '/admin/user-stats/monthly';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 기간별 사용자 건수 통계 조회
   * ex) 최근 7일간 1회이상 접속
   */
  dayCountList = (
    params: RequestDataBase,
  ): Promise<{
    totalUserCount: number;
    day7: UserCountDays;
    day14: UserCountDays;
    day21: UserCountDays;
    day31: UserCountDays;
  }> => {
    const url = '/admin/user-stats/day-count-list';
    return this.withData.post(url, ...splitParams(params));
  };
}
