import { ApiHelperWithData, splitParams, type ApiHelper } from '@ocodelib/api-common';
import type {
  BbsKindKey,
  BbsPostReadPolicyKey,
  BbsPostStateKey,
  BbsPostWritePolicyKey,
  BbsStateKey,
} from '../enum-types';
import type { RequestDataBase } from '../model/common';
import type {
  Bbs,
  BbsPost,
  BbsPostCountInfo,
  BbsPostSimple,
  BbsReply,
  NextPageData,
  PagerData,
  UploadedFile,
} from '../model/index';

export class BbsApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 게시판 목록
   */
  bbsList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      kind?: BbsKindKey;
      cate?: string;
      bandId?: number;
      bandName?: string;
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<Bbs> }> => {
    const url = '/admin/api/bbs-manage/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 게시판 정보 by bbsId
   */
  bbsInfo = (
    params: {
      bbsId: number;
    } & RequestDataBase,
  ): Promise<{ bbs: Bbs }> => {
    const url = '/admin/api/bbs-manage/bbs-info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 게시판 정보 by slug
   */
  bbsInfoBySlug = (
    params: {
      bbsSlug: string;
    } & RequestDataBase,
  ): Promise<{ bbs: Bbs }> => {
    const url = '/admin/api/bbs-manage/bbs-info-by-slug';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 게시판 등록
   */
  bbsCreate = (
    params: {
      kind: BbsKindKey;
      slug: string;
      bbsName: string;
      bbsState: BbsStateKey;
      cates?: string;
      listPolicy: BbsPostReadPolicyKey;
      readPolicy: BbsPostReadPolicyKey;
      writePolicy: BbsPostWritePolicyKey;
      bandId?: number;
    } & RequestDataBase,
  ): Promise<{ bbs: Bbs }> => {
    const url = '/admin/api/bbs-manage/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 게시판 수정
   */
  bbsUpdate = (
    params: {
      bbsId: number;
      slug: string;
      bbsName: string;
      bbsState: BbsStateKey;
      cates?: string;
      listPolicy: BbsPostReadPolicyKey;
      readPolicy: BbsPostReadPolicyKey;
      writePolicy: BbsPostWritePolicyKey;
    } & RequestDataBase,
  ): Promise<{ bbs: Bbs }> => {
    const url = '/admin/api/bbs-manage/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 게시판 삭제
   */
  bbsDelete = async (
    params: {
      bbsId: number;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/bbs-manage/bbs-remove';
    await this.withData.post(url, ...splitParams(params));
  };

  /**
   * 게시물 등록
   */
  postCreate = (
    params: {
      bbsSlug: string;
      title: string;
      content: string;
      cate?: string;
      attachFileIds: string[];
    } & RequestDataBase,
  ): Promise<{ post: BbsPost }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-create`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 수정
   */
  postUpdate = (
    params: {
      postId: number;
      bbsSlug: string;
      title: string;
      content: string;
      cate?: string;
      attachFileIds: string[];
    } & RequestDataBase,
  ): Promise<{ post: BbsPost }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-update`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 상태 수정
   */
  postUpdateState = (
    params: {
      bbsSlug: string;
      postId: number;
      postState: BbsPostStateKey;
    } & RequestDataBase,
  ): Promise<{ post: BbsPost }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-update-state`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 목록
   */
  postList = (
    params: {
      bbsSlug: string;
      pageNumber: number;
      rowsPerPage: number;
      title?: string;
      nickName?: string;
      cate?: string;
      bandId?: string;
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<BbsPostSimple> }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/posts`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 정보 조회
   */
  postInfo = (
    params: {
      bbsSlug: string;
      postId: number;
    } & RequestDataBase,
  ): Promise<{ post: BbsPost; bbs: Bbs }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물의 카운트 정보 조회
   */
  postCountInfo = (
    params: {
      bbsSlug: string;
      postId: number;
    } & RequestDataBase,
  ): Promise<{ countInfo: BbsPostCountInfo }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/p/api/bbs/${bbsSlug}/post-count-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 삭제
   */
  postDelete = async (
    params: {
      bbsSlug: string;
      postId: number;
    } & RequestDataBase,
  ): Promise<void> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-delete`;
    await this.helper.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물의 좋아요 추가
   * 게시물의 좋아요 건수 리턴
   */
  postFavorAdd = (
    params: {
      bbsSlug: string;
      postId: number;
    } & RequestDataBase,
  ): Promise<{ value: number }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-favor-add`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물의 좋아요 제거
   * 게시물의 좋아요 건수 리턴
   */
  postFavorRemove = (
    params: {
      bbsSlug: string;
      postId: number;
    } & RequestDataBase,
  ): Promise<{ value: number }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/post-favor-remove`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 등록
   */
  replyCreate = (
    params: {
      bbsSlug: string;
      postId: number;
      content: string;
      attachFileIds?: string[];
    } & RequestDataBase,
  ): Promise<{ reply: BbsReply }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-create`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 수정
   */
  replyUpdate = (
    params: {
      bbsSlug: string;
      replyId: number;
      content: string;
      attachFileIds?: string[];
    } & RequestDataBase,
  ): Promise<{ reply: BbsReply }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-update`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 정보 조회
   */
  replyInfo = (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ reply: BbsReply }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 목록 조회
   */
  replyList = (
    params: {
      bbsSlug: string;
      pageNumber: number;
      rowsPerPage: number;
      postId?: number;
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<BbsReply> }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-list`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 목록 조회 - nextKey 페이징
   */
  replyNextPage = (
    params: {
      bbsSlug: string;
      postId?: number;
      bandId?: number;
      content?: string;
      nickName?: string;
      accountId?: number;
      nextKey?: string;
      maxCount: number;
      isAscending: boolean;
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<BbsReply> }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-next-page`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 삭제
   * 게시물의 댓글 건수를 리턴합니다.
   */
  replyDelete = async (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ value: number }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-delete`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글의 좋아요 추가
   * 댓글의 좋아요 건수 리턴
   */
  replyFavorAdd = (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ value: number }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-favor-add`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글의 좋아요 제거
   * 댓글의 좋아요 건수 리턴
   */
  replyFavorRemove = (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ value: number }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-favor-remove`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 고정 추가
   */
  replyPinAdd = (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ reply: BbsReply }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-pin-add`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 게시물 댓글 고정 제거
   */
  replyPinRemove = (
    params: {
      bbsSlug: string;
      replyId: number;
    } & RequestDataBase,
  ): Promise<{ reply: BbsReply }> => {
    const { bbsSlug, ...restParams } = params;
    const url = `/admin/api/bbs/${bbsSlug}/reply-pin-remove`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 첨부파일 또는 게시물 본문의 data url 부분을 업로드
   */
  postUploadFile = async (
    params: {
      file: Blob;
      fileName: string;
      bbsSlug: string;
      isAttachFile: boolean;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { ctx, isAttachFile, bbsSlug, file, fileName } = params;
    const url = `/admin/api/bbs/${bbsSlug}/upload-post-file`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('isAttachFile', '' + isAttachFile);
    return this.withData.postMultipart(url, formData, { ctx });
  };

  /**
   * 게시물 댓글의 본문에서 data url 부분을 업로드
   */
  replyUploadFile = async (
    params: {
      file: Blob;
      fileName: string;
      bbsSlug: string;
      isAttachFile: boolean;
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { ctx, isAttachFile, bbsSlug, file, fileName } = params;
    const url = `/admin/api/bbs/${bbsSlug}/upload-reply-file`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('isAttachFile', '' + isAttachFile);
    return this.withData.postMultipart(url, formData, { ctx });
  };
}
