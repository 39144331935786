import { ApiHelper, ApiHelperWithData, splitParams } from '@ocodelib/api-common';
import type { CodingImageCateForAdm, CodingImageZone, RequestDataBase } from '../model/index';

export class CodingImageZoneApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  list = (params: RequestDataBase): Promise<{ zoneList: CodingImageZone[] }> => {
    const url = '/admin/coding-img-zone/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = (
    params: {
      zoneId: string;
    } & RequestDataBase,
  ): Promise<{ zone: CodingImageZone }> => {
    const url = '/admin/coding-img-zone/info';
    return this.withData.post(url, ...splitParams(params));
  };

  create = (
    params: {
      zoneId: string;
      zoneName: string;
      disabled: boolean;
    } & RequestDataBase,
  ): Promise<{ zone: CodingImageZone }> => {
    const url = '/admin/coding-img-zone/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      zoneId: string;
      zoneName: string;
      disabled: boolean;
    } & RequestDataBase,
  ): Promise<{ zone: CodingImageZone }> => {
    const url = '/admin/coding-img-zone/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  delete = async (
    params: {
      zoneId: string;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/coding-img-zone/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  cateSort = async (
    params: {
      zoneId: string;
      cateIds: string[];
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/coding-img-zone/cate-sort';
    await this.helper.postJson(url, ...splitParams(params));
  };

  cateList = (
    params: {
      zoneId: string;
      isBg: boolean;
    } & RequestDataBase,
  ): Promise<{ cateList: CodingImageCateForAdm[] }> => {
    const url = '/admin/coding-img-zone/cate-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  cateListReset = async (
    params: {
      zoneId: string;
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/coding-img-zone/cate-list-reset';
    await this.helper.post(url, ...splitParams(params));
  };
}
