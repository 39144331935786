import {
  ApiHelper,
  type ErrorResponseInterceptor,
  type ResponseInterceptor,
} from '@ocodelib/api-common';
import { errorToMessage } from './errors/index';
import {
  AuthApi,
  BbsApi,
  CoppiaApi,
  GalleryApi,
  P5ProjectApi,
  ProjectApi,
  PyProjectApi,
  UserDatiApi,
  UserProfileApi,
  WebPropApi,
} from './user-main-apis/index';
import { createLazyApiGenerator } from './util/create-lazy-api-generator';

export class UserMainApi {
  readonly helper: ApiHelper;

  #lazyNew: ReturnType<typeof createLazyApiGenerator>;

  constructor(options: {
    apiBaseURL: string;
    createApiHeader: () => Record<string, string>;
    responseInterceptor: ResponseInterceptor;
    errorResponseInterceptor: ErrorResponseInterceptor;
    debug?: boolean;
  }) {
    //
    const { apiBaseURL, createApiHeader, responseInterceptor, errorResponseInterceptor, debug } =
      options;
    this.helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptor,
      errorToMessage,
      debug,
    );
    this.#lazyNew = createLazyApiGenerator(this);
  }

  get auth() {
    return this.#lazyNew(AuthApi);
  }

  get webProp() {
    return this.#lazyNew(WebPropApi);
  }

  get coppia() {
    return this.#lazyNew(CoppiaApi);
  }

  get userProfile() {
    return this.#lazyNew(UserProfileApi);
  }

  get userDati() {
    return this.#lazyNew(UserDatiApi);
  }

  /**
   * @deprecated ProjectApi
   */
  get p5Project() {
    return this.#lazyNew(P5ProjectApi);
  }

  /**
   * @deprecated ProjectApi
   */
  get pyProject() {
    return this.#lazyNew(PyProjectApi);
  }

  get project() {
    return this.#lazyNew(ProjectApi);
  }

  get gallery() {
    return this.#lazyNew(GalleryApi);
  }

  get bbs() {
    return this.#lazyNew(BbsApi);
  }
}
